<template>
  <div class="new-election">
    <wizard />
    <electionForm :election="election" />
  </div>
</template>

<script>
import election from "@/mixins/election";

import wizard from "@/components/wizard/wizard.vue";
import electionForm from "@/components/ballots/BallotForm.vue";

export default {
  mixins: [election],
  components: {
    wizard,
    electionForm
  }
};
</script>